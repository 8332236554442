<form class="form grid-12" #commitmentForm="ngForm">
    <div class="g-col-12">
        <esa-material-ff
            name="Title"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="commitmentUpsertDto.Title"
            [customLabel]="titleCustomLabel">
        </esa-material-ff>
        <ng-template #titleCustomLabel>
            <custom-form-label label="Title" fieldDefinitionType="Title"></custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="ClientCommitmentID"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="commitmentUpsertDto.ClientCommitmentID"
            [customLabel]="commitmentIDCustomLabel">
        </esa-material-ff>
        <ng-template #commitmentIDCustomLabel>
            <custom-form-label label="Commitment ID" fieldDefinitionType="CommitmentID"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="Source"
            [editMode]="editMode"
            [required]="true"
            type="dropdown"
            [options]="sources$ | async"
            optionLabel="Name"
            optionValue="SourceID"
            [(ngModel)]="commitmentUpsertDto.SourceID"
            [customLabel]="sourceCustomLabel"
            [customDisplay]="sourceCustomDisplay">
        </esa-material-ff>
        <ng-template #sourceCustomLabel>
            <custom-form-label label="Source" fieldDefinitionType="Source"></custom-form-label>
        </ng-template>
        <ng-template #sourceCustomDisplay>
            <a [routerLink]="['/commitment-library/source-documents/', commitment.Source.SourceID, 'summary-information']">
                <p>{{ commitment.Source.Name }}</p>
            </a>
        </ng-template>

        <esa-material-ff
            name="Project"
            [editMode]="false"
            [required]="true"
            type="dropdown"
            [options]="[]"
            optionLabel="Name"
            optionValue="ProjectID"
            label="Project"
            [customDisplay]="projectCustomDisplay"
            *ngIf="!editMode && commitment && commitment.ProjectRoutingData">
        </esa-material-ff>
        <ng-template #projectCustomDisplay>
            <a [routerLink]="['/commitment-library/projects/', commitment.ProjectRoutingData.ProjectID, 'summary-information']">
                <p>{{ commitment.ProjectRoutingData.Name }}</p>
            </a>
        </ng-template>
        <esa-material-ff
            name="Technical Leads"
            [editMode]="editMode"
            [required]="false"
            type="dropdown"
            [options]="users$ | async"
            optionLabel="FullName"
            optionValue="UserID"
            [dropdownMultiple]="true"
            [(ngModel)]="commitmentUpsertDto.TechnicalLeadIDs"
            [customLabel]="technicalLeadsCustomLabel">
        </esa-material-ff>
        <ng-template #technicalLeadsCustomLabel>
            <custom-form-label label="Technical Leads" fieldDefinitionType="CommitmentTechnicalLead"></custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="CommitmentType"
            [editMode]="editMode"
            [required]="true"
            type="dropdown"
            [options]="commitmentTypes$ | async"
            optionLabel="Name"
            optionValue="CommitmentTypeID"
            [(ngModel)]="commitmentUpsertDto.CommitmentTypeID"
            [customLabel]="commitmentTypeCustomLabel">
        </esa-material-ff>
        <ng-template #commitmentTypeCustomLabel>
            <custom-form-label label="Commitment Type" fieldDefinitionType="CommitmentType"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="ResourceCategory"
            [editMode]="editMode"
            [required]="false"
            type="dropdown"
            [options]="resourceCategories$ | async"
            optionLabel="Name"
            optionValue="ResourceCategoryID"
            [(ngModel)]="commitmentUpsertDto.ResourceCategoryID"
            [customLabel]="ResourceCategoryCustomLabel">
        </esa-material-ff>
        <ng-template #ResourceCategoryCustomLabel>
            <custom-form-label label="Resource Category" fieldDefinitionType="ResourceCategory"></custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-12">
        <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
            <custom-form-label label="Full Commitment Text" fieldDefinitionType="FullCommitmentText"></custom-form-label>
            <div class="value" *ngIf="editMode">
                <div class="grid-12">
                    <div class="g-col-12">
                        <tinymce-editor
                            #tinyMceEditor
                            name="FullCommitmentText"
                            [(content)]="commitmentUpsertDto.FullCommitmentText"
                            [isRequired]="true"></tinymce-editor>
                    </div>
                </div>
            </div>
            <div class="value-display" *ngIf="!editMode" [innerHTML]="commitmentUpsertDto.FullCommitmentText | bypassHtml"></div>
        </div>
    </div>

    @if (editMode) {
        <div class="g-col-12 mt-4">
            <div class="kv-pair">
                <esa-label label="Files"></esa-label>
                <div class="value file-upload">
                    <file-drop-upload [multiple]="true" (filesChanged)="uploadFilesChanged($event)" />
                    @if (files.length > 0) {
                        <div class="file-upload__files mt-3">
                            @for (file of files; track file) {
                                <simple-file-display
                                    [isNotYetUploaded]="true"
                                    [fileName]="file.name"
                                    [displayDelete]="true"
                                    (deleteFile)="deleteNewFile(file)"></simple-file-display>
                            }
                        </div>
                    }
                </div>
            </div>
            @if (isLoading) {
                <beacon-loading-overlay>
                    Saving Evidence of Compliance...
                    <loading-spinner color="accent" [diameter]="50" />
                </beacon-loading-overlay>
            }
        </div>
    } @else {
        <div class="g-col-12">
            <commitment-file-list></commitment-file-list>
        </div>
    }

    <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
        <span [matTooltip]="!commitmentForm.form.valid ? 'You are missing requried fields.' : ''">
            <button mat-flat-button style="margin-right: 1rem" color="primary" (click)="saveForm(form)" [disabled]="!commitmentForm.form.valid">
                <mat-icon>save</mat-icon>
                Save
            </button>
        </span>

        <button mat-button color="primary" (click)="cancelEditMode()"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
</form>
