import { Injectable } from "@angular/core";
import { FileService } from "./file.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { ActivatedRoute } from "@angular/router";
import { IButtonRendererClickEvent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";
import { Subscription } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class FileDownloadService {
    private fileDownloadSubscription: Subscription;

    constructor(private fileService: FileService, private alertService: AlertService, private route: ActivatedRoute) {}

    downloadFile(evidenceOfComplianceID: string, evidenceOfComplianceTitle: string, onComplete: () => void) {
        this.fileDownloadSubscription = this.fileService.downloadFile(`evidence-of-compliance-file/${evidenceOfComplianceID}/all-files`).subscribe({
            next: (response) => {
                if (response) {
                    this.fileService.handleResponse(response, `EvidenceOfCompliance_${evidenceOfComplianceTitle}_${evidenceOfComplianceID}.zip`);
                }
            },
            error: (e) => {
                this.alertService.pushAlert(new Alert("Error zipping files.", AlertContext.Danger));
                onComplete();
            },
            complete: () => {
                onComplete();
            },
        });
    }

    downloadAllFilesForComponent(componentID: string, onComplete: () => void) {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split("T")[0].replace(/-/g, "_");
        this.fileDownloadSubscription = this.fileService.downloadFile(`evidence-of-compliance-file/component/${componentID}/all-files`).subscribe({
            next: (response) => {
                if (response) {
                    this.fileService.handleResponse(response, `EvidenceOfCompliance_Component_${formattedDate}.zip`);
                }
            },
            error: (e) => {
                this.alertService.pushAlert(new Alert("Error zipping files.", AlertContext.Danger));
                onComplete();
            },
            complete: () => {
                onComplete();
            },
        });
    }

    downloadAllFilesForChecklistItem(checklistItemID: string, onComplete: () => void) {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split("T")[0].replace(/-/g, "_");
        this.fileDownloadSubscription = this.fileService.downloadFile(`evidence-of-compliance-file/checklist-item/${checklistItemID}/all-files`).subscribe({
            next: (response) => {
                if (response) {
                    this.fileService.handleResponse(response, `EvidenceOfCompliance_ChecklistItem_${checklistItemID}_${formattedDate}.zip`);
                }
            },
            error: (e) => {
                this.alertService.pushAlert(new Alert("Error zipping files.", AlertContext.Danger));
                onComplete();
            },
            complete: () => {
                onComplete();
            },
        });
    }
}
