<mat-card appearance="outlined" class="card-wrapper">
    <div class="flex-between wrap" style="margin-bottom: 2rem">
        <div class="flex-between wrap">
            <a [routerLink]="'/commitments/' + versionData.CommitmentID + '/summary-information'"><mat-icon>file_open</mat-icon></a>
            <h2 style="margin: 0 1rem 0 1rem">VERSION {{ versionData.VersionNumber }}</h2>
            <div>
                <ul>
                    <li [style.color]="listColor">
                        <span style="color: rgba(0, 0, 0, 0.54)">{{ versionData.CommitmentVersionStatus.CommitmentVersionStatusDisplayName | uppercase }}</span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="flex-between wrap">
            <mat-card-subtitle style="margin-bottom: 0">
                Created by {{ versionData.CreateUser.FullName }} on {{ versionData.CreateDate | date: "shortDate" }}&nbsp; &nbsp; &nbsp;

                <ng-container *ngIf="versionData.ApprovedUser">
                    Approved by {{ versionData.ApprovedUser.FullName }} on {{ versionData.ApprovedDate | date: "shortDate" }}
                </ng-container>
            </mat-card-subtitle>
        </div>
    </div>
    <mat-card-content>
        <div class="kv-pair">
            <h2 class="key" style="flex-basis: 17%">Originator of Change:</h2>
            <div class="value">{{ versionData.OriginatorOfChange || "-" }}</div>
        </div>

        <div class="kv-pair">
            <h2 class="key" style="flex-basis: 17%">Summary of Change:</h2>
            <div class="value" *ngIf="!versionData.SummaryOfChange">-</div>
            <div class="value" *ngIf="versionData.SummaryOfChange" [innerHTML]="versionData.SummaryOfChange | bypassHtml"></div>
        </div>
    </mat-card-content>
</mat-card>
<div class="vert-line" *ngIf="!isLast"></div>
