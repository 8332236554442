import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs/internal/Subscription";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { CommitmentGridDto } from "src/app/shared/generated/model/commitment-grid-dto";
import { UserDto, TenantDto, CommitmentSimpleDto } from "src/app/shared/generated/model/models";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { NgIf } from "@angular/common";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";

@Component({
    selector: "commitment-list",
    templateUrl: "./commitment-list.component.html",
    styleUrls: ["./commitment-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ClearGridFiltersButtonComponent, MatButton, MatIcon, AgGridAngular, GridActionsComponent],
})
export class CommitmentListComponent implements OnInit, OnDestroy {
    @ViewChild("commitmentsGrid") commitmentsGrid: AgGridAngular;

    public currentUser: UserDto;
    public commitments: CommitmentGridDto[];

    public rowData = [];
    public columnDefs: ColDef[];

    public currentTenant: TenantDto;

    user: Subscription;
    getCommitmentsRequest: Subscription;

    constructor(
        private commitmentService: CommitmentService,
        private tenantService: TenantService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private gridCommonService: GridCommonService
    ) {
        this.columnDefs = [
            {
                headerName: "ID",
                headerTooltip: "Commitment ID",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.CommitmentID,
                        LinkDisplay: params.data.ClientCommitmentID,
                        ChildRoute: "summary-information",
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/commitment-library/commitments/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.ClientCommitmentID;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                type: "rightAligned",
                width: 170,
                sort: "asc",
            },
            {
                headerName: "Title",
                field: "Title",
                tooltipField: "Title",
                flex: 4,
            },
            {
                headerName: "Type",
                field: "CommitmentTypeName",
                tooltipField: "CommitmentTypeName",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "CommitmentTypeName",
                },
            },
            {
                headerName: "Resource Category",
                headerTooltip: "Resource Category",
                field: "ResourceCategoryName",
                tooltipField: "ResourceCategoryName",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ResourceCategoryName",
                },
            },
            {
                headerName: "Status",
                headerTooltip: "Version Status",
                field: "CommitmentVersionStatusName",
                flex: 1.5,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "CommitmentVersionStatusName",
                },
            },
            {
                headerName: "Version",
                headerTooltip: "Version",
                field: "Version",
                filter: "agNumberColumnFilter",
                type: "numericColumn",
                flex: 1.5,
            },
            {
                headerName: "Latest Version?",
                headerTooltip: "Is Latest Version?",
                field: "IsLatestVersionYesNo",
                flex: 2,
            },
        ];

        this.tenantService.currentTenant$.subscribe((currentTenant) => {
            this.currentTenant = currentTenant;
            this.cdr.markForCheck();
        });
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.getCommitmentsRequest?.unsubscribe();
    }

    navigateToCommitmentCreatePage() {
        this.router.navigate(["/commitment-library/commitments/create"]);
    }

    onCommitmentsGridReady(gridEvent) {
        this.commitmentsGrid.api.showLoadingOverlay();

        this.getCommitmentsRequest = this.commitmentService.commitmentsSimpleGet().subscribe((results) => {
            this.commitments = results;
            this.rowData = results;

            let projectIDs = this.commitments.map((c) => c.ProjectRoutingData.ProjectID);
            let distinctProjectIDs = projectIDs.reduce((unique, projectID) => {
                return unique.includes(projectID) ? unique : [...unique, projectID];
            }, []);

            if (distinctProjectIDs.length > 1) {
                //Insert project column after the title column.
                let indexOfTitleColumn = this.columnDefs.findIndex((cd) => cd.headerName === "Title");
                this.columnDefs.splice(indexOfTitleColumn + 1, 0, {
                    headerName: "Project",
                    headerTooltip: "Project",
                    valueGetter: function (params: any) {
                        return {
                            LinkValue: params.data.ProjectRoutingData.ProjectID,
                            LinkDisplay: params.data.ProjectRoutingData.Name,
                            ChildRoute: "summary-information",
                        };
                    },
                    cellRendererSelector: () => this.gridCommonService.renderLink("/commitment-library/projects/"),
                    filter: CustomDropdownFilterComponent,
                    filterParams: {
                        field: "ProjectRoutingData",
                        fieldPropertyDisplay: "LinkDisplay", //This is the property that will be displayed in the dropdown.
                        columnContainsMultipleValues: false,
                    },
                    cellRenderer: LinkRendererComponent,
                    cellRendererParams: { inRouterLink: "/commitment-library/projects/" },
                    filterValueGetter: function (params: any) {
                        return params.data.ProjectRoutingData.Name;
                    },
                    comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                        let valueA = linkA.LinkDisplay.toLowerCase();
                        let valueB = linkB.LinkDisplay.toLowerCase();

                        return valueA.localeCompare(valueB, undefined, {
                            numeric: true,
                            sensitivity: "base",
                        });
                    },
                    flex: 3,
                });

                this.commitmentsGrid.api.setColumnDefs(this.columnDefs);
            }

            setTimeout(() => {
                this.setDefaultFilter();
            });

            this.cdr.markForCheck();
        });
    }

    get canCreateCommitment(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Create);
    }

    setDefaultFilter() {
        this.commitmentsGrid.api.setFilterModel({
            IsLatestVersionYesNo: {
                filterType: "text",
                type: "equals",
                filter: "Yes",
            },
        });

        this.commitmentsGrid.api.onFilterChanged();
        this.cdr.markForCheck();
    }
}
