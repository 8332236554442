<div class="component-summary">
    <div class="component-summary__header">
        <a [routerLink]="['projects', component.ProjectID, 'components', component.ComponentID]">
            <h2><beacon-entity-icon entity="Component" matTooltip="Component" /> {{ component.Name }}</h2>
        </a>

        <pill-component-status [componentStatusID]="component.Status.ComponentStatusID" />
    </div>

    @if (component.Description) {
        <div class="component-summary__content">
            <div [innerHTML]="component.Description"></div>
        </div>
    }

    <div class="component-summary__footer">
        <div>
            Associated Commitments: <strong>{{ component.CountCommitments }}</strong>
        </div>
        <div>
            Complete Compliance Requirements: <strong>{{ component.CountCompletedChecklistItems }}/{{ component.CountChecklistItems }}</strong>
        </div>
    </div>
</div>
