import { Component, Input } from "@angular/core";
import { ProjectSummaryDto } from "../../generated/model/project-summary-dto";
import { ComponentSummaryComponent } from "../component-summary/component-summary.component";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { PillComponent } from "../pill/pill.component";
import { DatePipe } from "@angular/common";
import { BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";

@Component({
    selector: "project-summary",
    standalone: true,
    imports: [ComponentSummaryComponent, BeaconEntityIconComponent, MatTooltip, RouterModule, PillComponent, DatePipe],
    templateUrl: "./project-summary.component.html",
    styleUrl: "./project-summary.component.scss",
})
export class ProjectSummaryComponent {
    @Input() project: ProjectSummaryDto;
}
