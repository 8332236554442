<div class="compliance-requirement-drag-drop">
    <div class="projected-content">
        <ng-content></ng-content>
    </div>
    <div class="content">
        <div class="content-start">
            <checklist-item-status [checklistItemStatus]="checklistItemStatus"></checklist-item-status>
            <pill-evidence-of-compliance-file-count [fileCount]="numberOfEvidenceFiles" [evidenceNames]="evidenceNames">
            </pill-evidence-of-compliance-file-count>
            @if (linkToChecklistItem) {
            <a [routerLink]="['checklist-items', checklistItemID]"
                ><span class="ellipsis" [title]="complianceRequirementName">{{ complianceRequirementName }}</span></a
            >
            } @else {
            <span class="ellipsis" [title]="complianceRequirementName">{{ complianceRequirementName }}</span>
            }
            <pill matTooltip="Commitment ID">
                {{ clientCommitmentID }}
            </pill>
        </div>
        <div class="content-end">
            <pillbox theme="Phase" [matTooltip]="'Phase: ' + phase">{{ phase ?? "N/A" }}</pillbox>
            <pillbox theme="Scope" [matTooltip]="'Scope: ' + scope">{{ scope ?? "N/A" }}</pillbox>
            <pillbox theme="Frequency" [matTooltip]="'Frequency: ' + frequency">{{ frequency ?? "N/A" }}</pillbox>
            <pillbox theme="ComplianceRequirementType" [matTooltip]="'Compliance Requirement Type: ' + complianceRequirementType">{{
                complianceRequirementType ?? "N/A"
            }}</pillbox>
            <pillbox theme="ResourceCategory" [matTooltip]="'Resource Category: ' + resourceCategory">{{ resourceCategory ?? "N/A" }}</pillbox>
        </div>
    </div>
</div>
