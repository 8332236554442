<div class="detail-view" *ngIf="project$ | async as projectForView">
    <div class="actions-bar">
        <button mat-icon-button *ngIf="canEdit()" color="primary" (click)="enableEditMode()"><mat-icon>edit</mat-icon></button>
    </div>

    <project-form
        #updateProjectForm
        [project]="projectForView"
        [editMode]="editMode"
        (formSubmitted)="save()"
        [showFileUpload]="true"
        (cancelEditModeChange)="cancelEdit()"></project-form>

    <beacon-back-to-top></beacon-back-to-top>
</div>
