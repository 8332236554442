import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { FileService } from "src/app/services/file/file.service";
import { TinymceEditorComponent } from "src/app/shared/components/tinymce-editor/tinymce-editor.component";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { NgIf, NgFor } from "@angular/common";
import { TinymceEditorComponent as TinymceEditorComponent_1 } from "../../../shared/components/tinymce-editor/tinymce-editor.component";
import { CustomFormLabelComponent } from "../../../shared/components/custom-form-label/custom-form-label.component";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { FormsModule } from "@angular/forms";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
    selector: "version-dialog",
    templateUrl: "./version-dialog.component.html",
    styleUrls: ["./version-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        FormsModule,
        EsaMaterialFormFieldComponent,
        CustomFormLabelComponent,
        TinymceEditorComponent_1,
        MatButton,
        MatIcon,
        NgIf,
        NgFor,
        MatIconButton,
        MatDialogActions,
        MatTooltip,
    ],
})
export class VersionDialogComponent implements OnInit {
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;

    public originatorOfChange: string;
    public summaryOfChange: string;
    public filesToUpload: File[] = [];

    //MK 3/29/2023 -- After a lot of trial and error finally got the popperjs to work in a material dialog for field-definitions.
    // I know its janky but it seems to work.
    public popperOptions?: any = {
        placement: "bottom",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: ({ placement, reference, popper }) => {
                        return [0, -150];
                    },
                },
            },
        ],
    };

    public isLoading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<VersionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fileService: FileService,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {}

    onFilesSelected(files) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            this.filesToUpload.push(file);
        }
    }

    version() {
        let route = `commitment-library/commitments/${this.data.CommitmentID}/version`;
        let formValues: [string, string][] = [
            ["originatorOfChange", this.originatorOfChange],
            ["summaryOfChange", this.summaryOfChange],
        ];

        this.isLoading = true;
        this.fileService.uploadFile(route, this.filesToUpload, formValues).subscribe({
            next: (result) => {
                if (result && result.body) {
                    this.dialogRef.close(result.body);
                    this.alertService.pushAlert(new Alert("Successfully versioned Commitment.", AlertContext.Success));
                }
            },
            complete: () => {
                this.isLoading = false;
            },
        });
    }

    spliceFiles(index) {
        this.filesToUpload.splice(index, 1);
    }

    cancel() {
        this.dialogRef.close();
    }
}
