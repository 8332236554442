import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import { FileService } from "src/app/services/file/file.service";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { SourceDto } from "src/app/shared/generated/model/models";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { AlertService } from "src/app/shared/services/alert.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";

import { NgIf, AsyncPipe } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
    selector: "source-document-related-commitments",
    templateUrl: "./source-document-related-commitments.component.html",
    styleUrls: ["./source-document-related-commitments.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ClearGridFiltersButtonComponent, AgGridAngular, AsyncPipe, MatButton, MatIcon, RouterLink, GridActionsComponent, MatTooltip],
})
export class SourceDocumentRelatedCommitmentsComponent implements OnInit, OnDestroy {
    @ViewChild("fileInput") fileInput: ElementRef;
    @ViewChild("commitmentsGrid") commitmentsGrid: AgGridAngular;

    public sourceDocument$: Observable<any>;
    public sourceDocument: SourceDto;

    public currentUser: UserDto;
    public commitments: CommitmentDto[];

    public rowData = [];
    public columnDefs: ColDef[];

    public filesToUpload: File[] = [];

    user: Subscription;
    getCommitmentsRequest: Subscription;
    fileUploadSub: Subscription;

    constructor(
        private currentSourceDocumentService: CurrentSourceDocumentService,
        private commitmentService: CommitmentService,
        private fileService: FileService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private gridCommonService: GridCommonService
    ) {
        this.columnDefs = [
            {
                headerName: "ID",
                headerTooltip: "Commitment ID",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.CommitmentID,
                        LinkDisplay: params.data.ClientCommitmentID,
                        ChildRoute: "summary-information",
                    };
                },
                cellRendererSelector: () => this.gridCommonService.renderLink("/commitment-library/commitments/"),
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.ClientCommitmentID;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                type: "rightAligned",
                width: 170,
                sort: "asc",
            },
            {
                headerName: "Title",
                field: "Title",
                tooltipField: "Title",
                flex: 4,
            },
            {
                headerName: "Type",
                field: "CommitmentType.Name",
                tooltipField: "CommitmentType.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "CommitmentType.Name",
                },
            },
            {
                headerName: "Resource Category",
                headerTooltip: "Resource Category",
                field: "ResourceCategory.Name",
                tooltipField: "ResourceCategory.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ResourceCategory.Name",
                },
            },
            {
                headerName: "Status",
                headerTooltip: "Version Status",
                field: "CommitmentVersionStatus.CommitmentVersionStatusDisplayName",
                flex: 1.5,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "CommitmentVersionStatus.CommitmentVersionStatusDisplayName",
                },
            },
            {
                headerName: "Version",
                headerTooltip: "Version",
                field: "VersionNumber",
                filter: "agNumberColumnFilter",
                type: "numericColumn",
                flex: 1.5,
            },
            {
                headerName: "Latest Version?",
                headerTooltip: "Is Latest Version?",
                field: "$IsLatestVersionYesNo",
                flex: 2,
            },
        ];
    }

    ngOnInit(): void {
        this.sourceDocument$ = this.currentSourceDocumentService.getCurrentSourceDocument().pipe(
            map((data) => {
                this.sourceDocument = data;
                return data;
            })
        );

        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.getCommitmentsRequest?.unsubscribe();
        this.fileUploadSub?.unsubscribe();
    }

    onCommitmentsGridReady(gridEvent: any) {
        this.refreshData();
    }

    refreshData() {
        this.commitmentsGrid.api.showLoadingOverlay();

        this.getCommitmentsRequest = this.commitmentService.commitmentsGet().subscribe((results) => {
            this.commitments = results.filter((c) => {
                return c.Source.SourceID == this.sourceDocument.SourceID;
            });

            this.rowData = this.commitments;

            this.rowData.forEach((rd) => {
                rd.$IsLatestVersionYesNo = rd.IsLatestVersion ? "Yes" : "No";
            });

            this.commitmentsGrid.api.hideOverlay();
            setTimeout(() => {
                this.setDefaultFilter();
            });

            this.cdr.markForCheck();
        });
    }

    navigateToCommitmentCreatePage() {
        this.router.navigate(["/commitment-library/commitments/create"], { queryParams: { SourceID: this.sourceDocument.SourceID } });
    }

    get canCreateCommitment(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Create);
    }

    setDefaultFilter() {
        this.commitmentsGrid.api.setFilterModel({
            $IsLatestVersionYesNo: {
                filterType: "text",
                type: "equals",
                filter: "Yes",
            },
        });

        this.commitmentsGrid.api.onFilterChanged();
        this.cdr.markForCheck();
    }

    downloadBulkCommitmentTemplate() {
        var options = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalseparator: ".",
            showLabels: true,
            showTitle: false,
            useBom: true,
            noDownload: false,
            headers: ["Commitment ID", "Title", "Commitment Type", "Resource Category", "Full Commitment Text"],
            useHeader: false,
            nullToEmptyString: true,
        };

        let data = [];
        return new AngularCsv(data, "Commitment Bulk Upload for Source Document Template", options);
    }

    bulkDownloadHelp() {
        this.router.navigate([]).then(() => {
            window.open("/custom-pages/commitment-bulk-import-instructions", "_blank");
        });
    }

    uploadBulkCommitments(files: any) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            this.filesToUpload.push(file);
        }

        this.fileUploadSub = this.fileService
            .uploadFile(`commitment-library/commitments/bulk-upload?SourceID=${this.sourceDocument.SourceID}`, this.filesToUpload)
            .subscribe({
                next: (result) => {
                    if (JSON.stringify(result) != '{"type":0}') {
                        this.filesToUpload = [];
                        this.fileInput.nativeElement.value = null;
                        this.alertService.pushAlert(new Alert("Successfully bulk imported commitments.", AlertContext.Success), 5000);
                        this.refreshData();
                    }
                },
                error: (e) => {},
                complete: () => {},
            });
    }
}
