import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Configuration } from "src/app/shared/generated/configuration";
import { ApiService } from "src/app/shared/services";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";

@Injectable({
    providedIn: "root",
})
export class FileService {
    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private configuration: Configuration,
        private tenantService: TenantService
    ) {}

    downloadFile(fileRoute: string): Observable<any> {
        const route = `${this.getApiUrl()}/${fileRoute}/download`;

        const headers = new HttpHeaders();
        const req = new HttpRequest("GET", route, {
            headers,
            responseType: "blob",
        });
        return this.http.request(req).pipe(map((response: HttpResponse<Blob>) => response.body));
    }

    handleResponse(response: Blob, fileName: string) {
        const file = new File([response], fileName);
        if (navigator.msSaveBlob) {
            return navigator.msSaveBlob(file, file.name);
        }
        this.downloadAsHref(URL.createObjectURL(file), file.name);
    }

    downloadAsHref(href: string, filename: string) {
        const tempDownloadLink = document.createElement("a");
        tempDownloadLink.href = href;
        tempDownloadLink.download = filename;
        document.body.appendChild(tempDownloadLink);
        tempDownloadLink.click();
        document.body.removeChild(tempDownloadLink);
    }

    uploadFile(fileRoute: string, filesToUpload: any[], additionFormData: [string, string][] = []): Observable<any> {
        const route = `${this.getApiUrl()}/${fileRoute}`;

        const formData = new FormData();

        filesToUpload.forEach((file) => {
            formData.append(file.name, file);
        });

        additionFormData.forEach((tuple) => {
            formData.append(tuple[0], tuple[1]);
        });

        const headers = new HttpHeaders();
        const req = new HttpRequest("POST", route, formData, {
            headers,
            responseType: "json",
        });

        return this.http.request(req).pipe(
            catchError((error: any) => {
                return this.apiService.handleError(error);
            })
        );
    }

    private getApiUrl() {
        let tenantBasePath = this.tenantService.getTenantHostName(this.configuration.basePath);
        return tenantBasePath;
    }
}
