.
<div class="list-view">
    <div class="g-col-12">
        <div class="table-responsive" style="height: 800px">
            <beacon-grid-actions [grid]="componentsGrid" downloadFileName="project-related-components">
                <a mat-flat-button color="primary" [routerLink]="['/compliance-tracking', 'components', 'create']"> <mat-icon>add</mat-icon> Component </a>
            </beacon-grid-actions>
            <ag-grid-angular
                #componentsGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [suppressMenuHide]="true"
                (gridReady)="onComponentsGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
