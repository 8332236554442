import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { MaterialChipsRendererComponent } from "src/app/shared/components/ag-grid/material-chips-renderer/material-chips-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { ComplianceRequirementService } from "src/app/shared/generated/api/compliance-requirement.service";
import { ComplianceRequirementDto } from "src/app/shared/generated/model/compliance-requirement-dto";
import { TenantDto } from "src/app/shared/generated/model/tenant-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { ComplianceRequirementSimpleDto } from "src/app/shared/generated/model/models";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";

@Component({
    selector: "compliance-requirement-list",
    templateUrl: "./compliance-requirement-list.component.html",
    styleUrls: ["./compliance-requirement-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ClearGridFiltersButtonComponent, AgGridAngular, GridActionsComponent],
})
export class ComplianceRequirementListComponent implements OnInit, OnDestroy {
    @ViewChild("complianceRequirementGrid") complianceRequirementGrid: AgGridAngular;

    public currentUser: UserDto;
    public complianceRequirements: ComplianceRequirementSimpleDto[];

    public rowData = [];
    public columnDefs: ColDef[];

    public currentTenant: TenantDto;

    complianceReqirementsSubscription: Subscription;

    constructor(
        private gridCommonService: GridCommonService,
        private complianceRequirementService: ComplianceRequirementService,
        private tenantService: TenantService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.columnDefs = [
            {
                headerName: "Name",
                headerTooltip: "Compliance Requirement Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.CommitmentRoutingData.CommitmentID,
                        LinkDisplay: params.data.Name,
                        ChildRoute: "compliance-requirements",
                        QueryParams: {
                            activeComplianceRequirementID: params.data.ComplianceRequirementID,
                        },
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/commitment-library/commitments/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 400,
                autoHeight: true,
            },
            {
                headerName: "Commitment",
                headerTooltip: "Commitment ID",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.CommitmentRoutingData.CommitmentID,
                        LinkDisplay: params.data.CommitmentRoutingData.ClientCommitmentID,
                        ChildRoute: "summary-information",
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/commitment-library/commitments/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.CommitmentRoutingData.ClientCommitmentID;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                type: "rightAligned",
                width: 170,
                sort: "asc",
            },
            {
                headerName: "Resource Category",
                headerTooltip: "Resource Category",
                field: "ResourceCategoryName",
                tooltipField: "ResourceCategoryName",
                flex: 0.25,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ResourceCategoryName",
                },
            },
            {
                headerName: "Phase",
                headerTooltip: "Phase",
                field: "PhaseName",
                tooltipField: "PhaseName",
                flex: 0.15,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "PhaseName",
                },
            },
            {
                headerName: "Compliance Lead",
                headerTooltip: "Compliance Lead",
                field: "ComplianceLeadFullName",
                tooltipField: "ComplianceLeadFullName",
                flex: 0.15,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ComplianceLeadFullName",
                },
            },
            {
                headerName: "Tags",
                headerTooltip: "Tags",
                cellRenderer: MaterialChipsRendererComponent,
                cellRendererParams: function (params: any) {
                    return {
                        Tags: params.data.$TagsAsJSON,
                    };
                },
                filter: CustomDropdownFilterComponent,
                valueGetter: function (params: any) {
                    return {
                        DownloadDisplay: params.data.$TagsAsJSON.map((x) => x.Name).join(", "),
                    };
                },
                filterParams: {
                    columnContainsMultipleValues: true,
                },
                filterValueGetter: function (params: any) {
                    return params.data.$TagsAsJSON.map((x) => x.Name);
                },
                flex: 0.25,
                autoHeight: true,
            },
        ];

        this.tenantService.currentTenant$.subscribe((currentTenant) => {
            this.currentTenant = currentTenant;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.complianceReqirementsSubscription?.unsubscribe();
    }

    onComplianceRequirementGridReady(gridEvent: any) {
        this.complianceRequirementGrid.api.showLoadingOverlay();

        this.complianceReqirementsSubscription = this.complianceRequirementService.complianceRequirementsSimpleGet().subscribe((results) => {
            this.complianceRequirements = results;

            this.rowData = results;
            this.rowData.forEach((rd) => {
                rd.$TagsAsJSON = rd.Tags ? JSON.parse(rd.Tags) : [];
            });

            let projectIDs = this.complianceRequirements.map((c) => c.ProjectRoutingData.ProjectID);
            let distinctProjectIDs = projectIDs.reduce((unique, projectID) => {
                return unique.includes(projectID) ? unique : [...unique, projectID];
            }, []);

            if (distinctProjectIDs.length > 1) {
                //Insert project column after the title column.
                let indexOfPrecedingColumn = this.columnDefs.findIndex((cd) => cd.headerName === "Resource Category");
                this.columnDefs.splice(indexOfPrecedingColumn + 1, 0, {
                    headerName: "Project",
                    headerTooltip: "Project",
                    valueGetter: function (params: any) {
                        return {
                            LinkValue: params.data.ProjectRoutingData.ProjectID,
                            LinkDisplay: params.data.ProjectRoutingData.Name,
                            ChildRoute: "summary-information",
                        };
                    },
                    cellRenderer: LinkRendererComponent,
                    cellRendererParams: { inRouterLink: "/commitment-library/projects/" },
                    filter: CustomDropdownFilterComponent,
                    filterParams: {
                        field: "ProjectRoutingData",
                        fieldPropertyDisplay: "LinkDisplay", //This is the property that will be displayed in the dropdown.
                        columnContainsMultipleValues: false,
                    },
                    filterValueGetter: function (params: any) {
                        return params.data.ProjectRoutingData.Name;
                    },
                    comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                        let valueA = linkA.LinkDisplay.toLowerCase();
                        let valueB = linkB.LinkDisplay.toLowerCase();

                        return valueA.localeCompare(valueB, undefined, {
                            numeric: true,
                            sensitivity: "base",
                        });
                    },
                    flex: 0.25, //Not really sure how to best use flex here... Was thinking a flex of 1 would work, but I had to keep reducing it to make it look nice.
                });
            }

            this.complianceRequirementGrid.api.setColumnDefs(this.columnDefs);
            this.complianceRequirementGrid.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }
}
