<header>
    <!-- Top Nav -->
    <div class="top-nav">
        <div class="top-nav__tenant">
            @if (allowedTenants$ | async; as allowedTenants) {
                @if (currentTenant$ | async; as currentTenant) {
                    <div>
                        @if (allowedTenants.length > 1) {
                            <a [matMenuTriggerFor]="tenantMenu">{{ currentTenant.Name }} <mat-icon>expand_more</mat-icon></a>
                        } @else {
                            <span>{{ currentTenant.Name }}</span>
                        }
                        <mat-menu #tenantMenu>
                            <button
                                mat-menu-item
                                *ngFor="let tenant of allowedTenants"
                                href="javascript:void(0);"
                                (click)="changeTenant(tenant)"
                                [ngClass]="{ active: tenant.TenantID == currentTenant?.TenantID }">
                                {{ tenant.Name }}
                            </button>
                        </mat-menu>
                    </div>
                }
            }
            @if (customPages$ | async; as customPages) {
                @if (currentUser$ | async | withPermission: { permission: PermissionEnum.CustomPageRights, rights: RightsEnum.Read }) {
                    @if (customPages.viewPages.length > 0) {
                        <div routerLinkActive="active">
                            <a [matMenuTriggerFor]="viewMenu"> View <mat-icon>expand_more</mat-icon> </a>
                            <mat-menu #viewMenu>
                                @for (viewPage of customPages.viewPages; track viewPage) {
                                    <button mat-menu-item [routerLink]="['/custom-pages', viewPage.CustomPageVanityUrl]">
                                        {{ viewPage.CustomPageDisplayName }}
                                    </button>
                                }
                            </mat-menu>
                        </div>
                    }
                    @if (customPages.learnMorePages.length > 0) {
                        <div routerLinkActive="active">
                            <a [matMenuTriggerFor]="viewMenu"> Learn More <mat-icon>expand_more</mat-icon> </a>
                            <mat-menu #viewMenu>
                                @for (learnMorePage of customPages.learnMorePages; track learnMorePage) {
                                    <button mat-menu-item [routerLink]="['/custom-pages', learnMorePage.CustomPageVanityUrl]">
                                        {{ learnMorePage.CustomPageDisplayName }}
                                    </button>
                                }
                            </mat-menu>
                        </div>
                    }
                }
            }
        </div>
        <div class="top-nav__admin">
            <div *ngIf="environment.staging || environment.dev" class="qa-warning">
                <mat-icon>warning</mat-icon>
                {{ environment.staging ? "QA Site: Testing Environment!" : "Development Site: Testing Environment!" }}
            </div>
            @if (
                (currentUser$ | async | withPermission: { permission: PermissionEnum.UserRights, rights: RightsEnum.Read }) ||
                (currentUser$ | async | withPermission: { permission: PermissionEnum.FieldDefinitionRights, rights: RightsEnum.Read })
            ) {
                <a routerLink="/manage" role="button" routerLinkActive="active"><mat-icon [inline]="true">settings</mat-icon> Admin</a>
            }
        </div>
    </div>
    <!-- Primary Nav -->
    <div class="primary-nav">
        <div class="primary-nav__left">
            <!-- Logo -->
            <div class="logo">
                <a routerLink="/">
                    <img src="assets/main/logos/beacon-logo-white.svg" alt="Beacon Logo" />
                    <div class="ping-container">
                        <div class="ping"></div>
                    </div>
                </a>
            </div>

            <!-- Module Nav -->
            <div class="module-nav">
                @if (allowedModules$ | async; as allowedModules) {
                    <ul>
                        @for (allowedModule of allowedModules; track allowedModule) {
                            <a role="button" [title]="allowedModule.Module.Name" [routerLink]="['/', allowedModule.LandingPage]" routerLinkActive="active">
                                <module-icon [moduleID]="allowedModule.Module.ModuleID"></module-icon>
                                {{ allowedModule.Module.Name }}
                            </a>
                        }
                    </ul>
                }
            </div>
        </div>

        <!-- User Nav-->
        <div class="user-nav">
            @if (currentUser$ | async; as currentUser) {
                <a [matMenuTriggerFor]="userMenu">
                    <mat-icon>account_circle</mat-icon>
                    {{ (currentUser$ | async | userIsImpersonated) ? "Impersonating" : "Welcome" }} {{ currentUser?.FirstName + " " + currentUser?.LastName }}
                    <mat-icon>expand_more</mat-icon>
                </a>
                <mat-menu #userMenu>
                    <button mat-menu-item routerLink="/help">Get Help</button>
                    <button mat-menu-item (click)="editProfile()">Edit Profile</button>
                    <button mat-menu-item *ngIf="currentTenant$ | async as currentTenant" (click)="viewSystemInfo(currentTenant)">View System Info</button>
                    <button mat-menu-item (click)="logout()">
                        {{ (currentUser$ | async | userIsImpersonated) ? "Stop Impersonating" : "Sign Out" }}
                    </button>
                </mat-menu>
            } @else {
                <a href="javascript:void(0);" (click)="login()" routerLinkActive="active" class="nav-link"><mat-icon>account_circle</mat-icon>Sign In</a>
            }
        </div>
    </div>
</header>
