import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { ClearGridFiltersButtonComponent } from "src/app/shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { ComponentService } from "src/app/shared/generated/api/component.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { ComponentDto } from "src/app/shared/generated/model/models";
import { TenantDto } from "src/app/shared/generated/model/tenant-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";

@Component({
    selector: "component-list",
    templateUrl: "./component-list.component.html",
    styleUrls: ["./component-list.component.scss"],
    standalone: true,
    imports: [AgGridAngular, ClearGridFiltersButtonComponent, MatButton, MatIcon, NgIf, GridActionsComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentListComponent implements OnInit {
    @ViewChild("componentsGrid") componentsGrid: AgGridAngular;

    public currentUser: UserDto;
    public components: ComponentDto[];

    public rowData = [];
    public columnDefs: ColDef[];

    public currentTenant: TenantDto;

    userSubscription: Subscription;
    componentsSubscription: Subscription;

    constructor(
        private componentService: ComponentService,
        private tenantService: TenantService,
        private authenticationService: AuthenticationService,
        private dateColumnCreator: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) {
        this.columnDefs = [
            {
                headerName: "Name",
                headerTooltip: "Component Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.ComponentID,
                        LinkDisplay: params.data.Name,
                        ChildRoute: "summary-information",
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: function (params: any) {
                    return { inRouterLink: `/compliance-tracking/projects/${params.data.ProjectRoutingData.ProjectID}/components` };
                },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 400,
                sort: "asc",
            },
            {
                headerName: "Project",
                headerTooltip: "Project Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.ProjectRoutingData.ProjectID,
                        LinkDisplay: params.data.ProjectRoutingData.Name,
                        ChildRoute: "summary-information",
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/compliance-tracking/projects" },
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    columnContainsMultipleValues: false,
                },
                filterValueGetter: function (params: any) {
                    return params.data.ProjectRoutingData.Name;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 1,
            },
            {
                headerName: "Status",
                field: "Status.Name",
                flex: 1,
            },
            this.dateColumnCreator.createDateColumnDef("Start Date", "StartDate", "M/dd/YYYY"),
            this.dateColumnCreator.createDateColumnDef("Expected End Date", "ExpectedEndDate", "M/dd/YYYY"),
            {
                headerName: "Description",
                field: "$DescriptionWithoutHTML",
                flex: 2,
            },
        ];

        this.tenantService.currentTenant$.subscribe((currentTenant) => {
            this.currentTenant = currentTenant;
            this.cdr.markForCheck();
        });
    }

    ngOnInit(): void {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
        this.componentsSubscription?.unsubscribe();
    }

    navigateToCreateComponentPage() {
        this.router.navigate(["/compliance-tracking/components/create"]);
    }

    onComponentsGridReady(gridEvent) {
        this.componentsGrid.api.showLoadingOverlay();

        this.componentsSubscription = this.componentService.componentsGet().subscribe((results) => {
            this.components = results;
            this.rowData = results.map((c) => {
                return {
                    ComponentID: c.ComponentID,
                    Name: c.Name,
                    ProjectRoutingData: c.ProjectRoutingData,
                    Status: c.Status,
                    StartDate: c.StartDate,
                    ExpectedEndDate: c.ExpectedEndDate,
                    Description: c.Description,
                    $DescriptionWithoutHTML: c.Description ? c.Description.replace(/<[^>]*>/g, "") : null,
                };
            });
            this.componentsGrid.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }

    get canCreateComponent(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
    }
}
