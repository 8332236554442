<main class="main">
    <custom-rich-text [customRichTextTypeID]="CustomRichTextTypeEnum.CommitmentLibraryModule"></custom-rich-text>

    <div class="summary-item-wrapper">
        <a class="summary-item" [routerLink]="['projects']">
            <div class="summary-item__icon">
                <beacon-entity-icon [inline]="true" entity="Project" />
            </div>
            <div class="summary-item__content">
                <div class="summary-item__count">
                    @if (summary$ | async; as summary) {
                        {{ summary.ProjectCount }}
                    } @else {
                        <loading-spinner [diameter]="50" [strokeWidth]="5" />
                    }
                </div>
                <div class="summary-item__title">Projects</div>
            </div>
            <div class="summar-item__footer">(click to view all)</div>
        </a>
        <a class="summary-item" [routerLink]="['source-documents']">
            <div class="summary-item__icon">
                <beacon-entity-icon [inline]="true" entity="SourceDocument" />
            </div>
            <div class="summary-item__content">
                <div class="summary-item__count">
                    @if (summary$ | async; as summary) {
                        {{ summary.SourceDocumentCount }}
                    } @else {
                        <loading-spinner [diameter]="50" [strokeWidth]="5" />
                    }
                </div>
                <div class="summary-item__title">Source Documents</div>
            </div>
            <div class="summar-item__footer">(click to view all)</div>
        </a>
        <a class="summary-item" [routerLink]="['commitments']">
            <div class="summary-item__icon">
                <beacon-entity-icon [inline]="true" entity="Commitment" />
            </div>
            <div class="summary-item__content">
                <div class="summary-item__count">
                    @if (summary$ | async; as summary) {
                        {{ summary.CommitmentCount }}
                    } @else {
                        <loading-spinner [diameter]="50" [strokeWidth]="5" />
                    }
                </div>
                <div class="summary-item__title">Commitments</div>
            </div>
            <div class="summar-item__footer">(click to view all)</div>
        </a>
        <a class="summary-item" [routerLink]="['compliance-requirements']">
            <div class="summary-item__icon">
                <beacon-entity-icon [inline]="true" entity="ComplianceRequirement" />
            </div>
            <div class="summary-item__content">
                <div class="summary-item__count">
                    @if (summary$ | async; as summary) {
                        {{ summary.ComplianceRequirementCount }}
                    } @else {
                        <loading-spinner [diameter]="50" [strokeWidth]="5" />
                    }
                </div>
                <div class="summary-item__title">Compliance Requirements</div>
            </div>
            <div class="summar-item__footer">(click to view all)</div>
        </a>
    </div>
</main>
