<div class="main">
    <div class="page-header pb-3">
        <h1 class="page-title">New Project</h1>
        <div class="copy copy-2">
            <p>This page allows you to create a new project.</p>
        </div>
    </div>

    <project-form
        #createNewProjectForm
        [project]="model"
        [editMode]="true"
        (formSubmitted)="save($event)"
        [showFileUpload]="true"
        (cancelEditModeChange)="cancelEdit()">
    </project-form>
</div>
