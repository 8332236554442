import { AsyncPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltip } from "@angular/material/tooltip";
import { ActivatedRoute, NavigationEnd, Router, RouterModule, RouterOutlet } from "@angular/router";
import { filter, map, Observable, startWith, tap } from "rxjs";
import { routeParams } from "src/app/app.routes";
import { INavItem, ModuleHeaderComponent } from "src/app/shared/components/module-header/module-header.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { RouteHelpers } from "src/app/shared/services/router-helper/router-helper";
import { BeaconEntityIconComponent } from "../../shared/components/beacon-entity-icon/beacon-entity-icon.component";

@Component({
    selector: "compliance-tracking-router-outlet",
    standalone: true,
    imports: [RouterOutlet, ModuleHeaderComponent, AsyncPipe, RouterModule, MatMenuModule, MatIcon, MatTooltip, BeaconEntityIconComponent],
    templateUrl: "./compliance-tracking-router-outlet.component.html",
    styleUrl: "./compliance-tracking-router-outlet.component.scss",
})
export class ComplianceTrackingRouterOutletComponent implements OnInit {
    moduleMenuItems: INavItem[];
    projects$: Observable<ProjectDto[]>;
    currentProject$: Observable<ProjectDto>;

    constructor(
        private projectService: ProjectService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.projects$ = this.projectService.projectsGet().pipe(
            tap((projects) => {
                this.currentProject$ = this.router.events.pipe(
                    filter((event) => event instanceof NavigationEnd),
                    startWith(null),
                    map(() => {
                        const fullRoute = RouteHelpers.getCurrentRouteFromActivatedRouteSnapshot(this.route.snapshot);
                        if (Object.keys(fullRoute.params).includes(routeParams.projectID)) {
                            const projectID = fullRoute.params[routeParams.projectID];
                            return projects.find((project) => project.ProjectID === projectID);
                        }
                        return null;
                    })
                );
            })
        );
    }
}
