<div class="detail-view" *ngIf="project$ | async as project">
    <div style="justify-content: right; display: flex" *ngIf="projectFiles$ | async as projectFiles"></div>

    <div class="table-responsive" style="height: 500px">
        <div style="display: flex; align-items: center">
            <esa-label label="Files" style="margin-right: auto; margin-left: -10rem; margin-bottom: 13px"></esa-label>
            <beacon-grid-actions [grid]="projectFileGrid" downloadFileName="project-files" [downloadColIDsToExclude]="['0', '5']"></beacon-grid-actions>
        </div>
        <div style="height: calc(100% - 40px); overflow-y: auto">
            <ag-grid-angular
                #projectFileGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [suppressMenuHide]="true"
                (gridReady)="onProjectFileGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>

    <beacon-back-to-top></beacon-back-to-top>
</div>
