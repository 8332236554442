import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { ProjectDto } from "src/app/shared/generated/model/models";
import { TenantDto } from "src/app/shared/generated/model/tenant-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";

@Component({
    selector: "beacon-project-list",
    templateUrl: "./project-list.component.html",
    styleUrls: ["./project-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ClearGridFiltersButtonComponent, MatButton, MatIcon, AgGridAngular, GridActionsComponent, RouterModule],
})
export class ProjectListComponent implements OnInit, OnDestroy {
    @ViewChild("projectsGrid") projectGrid: AgGridAngular;

    public currentUser: UserDto;
    public projects: ProjectDto[];

    public rowData = [];
    public columnDefs: ColDef[];

    public currentTenant: TenantDto;

    userSubscription: Subscription;
    projectSubscription: Subscription;

    constructor(
        private projectService: ProjectService,
        private tenantService: TenantService,
        private authenticationService: AuthenticationService,
        private dateColumnCreator: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private gridCommonService: GridCommonService
    ) {
        this.columnDefs = [
            {
                headerName: "Name",
                headerTooltip: "Project Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.ProjectID,
                        LinkDisplay: params.data.Name,
                        ChildRoute: "summary-information",
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "../projects" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 400,
                sort: "asc",
            },
            this.dateColumnCreator.createDateColumnDef("Start Date", "StartDate", "M/dd/YYYY"),
            this.dateColumnCreator.createDateColumnDef("End Date", "EndDate", "M/dd/YYYY"),
            {
                headerName: "Description",
                field: "$DescriptionWithoutHTML",
                flex: 2,
            },
        ];

        this.tenantService.currentTenant$.subscribe((currentTenant) => {
            this.currentTenant = currentTenant;
            this.cdr.markForCheck();
        });
    }

    ngOnInit(): void {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
        this.projectSubscription?.unsubscribe();
    }

    onProjectsGridReady(gridEvent) {
        this.projectGrid.api.showLoadingOverlay();

        this.projectSubscription = this.projectService.projectsGet().subscribe((projects) => {
            this.projects = projects;
            this.rowData = projects.map((p) => {
                return {
                    ProjectID: p.ProjectID,
                    Name: p.Name,
                    StartDate: p.StartDate,
                    EndDate: p.EndDate,
                    Description: p.Description,
                    $DescriptionWithoutHTML: p.Description ? p.Description.replace(/<[^>]*>/g, "") : null,
                };
            });
            this.projectGrid.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }

    get canCreateProject(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
    }
}
