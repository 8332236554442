import { Component, Input } from "@angular/core";
import { RouterLink, RouterModule } from "@angular/router";
import { IWithPermission } from "../../pipes/with-permission/with-permission.pipe";
import { BeaconEntityType, BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";

@Component({
    selector: "module-header",
    standalone: true,
    imports: [RouterModule, BeaconEntityIconComponent],
    templateUrl: "./module-header.component.html",
    styleUrl: "./module-header.component.scss",
})
export class ModuleHeaderComponent {
    @Input() moduleMenuItems: INavItem[];
}

export interface INavItem {
    RouterLink: string | any[];
    Title: string;
    Permission?: IWithPermission;
    Icon?: typeof BeaconEntityType;
}
