<div class="project-summary">
    <div class="project-summary__header">
        <a [routerLink]="['projects', project.ProjectID]">
            <h2><beacon-entity-icon entity="Project" matTooltip="Project" /> {{ project.Name }}</h2>
        </a>
        <pill matTooltip="Start Date" backgroundColor="rgb(0 0 0 / 15%)" color="#000">Start Date: {{ project.StartDate | date }}</pill>
        <pill matTooltip="Start Date" backgroundColor="rgb(0 0 0 / 15%)" color="#000">End Date: {{ project.EndDate | date }}</pill>
    </div>

    <div [innerHTML]="project.Description"></div>

    <hr />
    <h3>Components</h3>
    <div class="components">
        @if (project.Components.length > 0) {
            @for (component of project.Components; track $index) {
                <component-summary [component]="component"></component-summary>
            }
        } @else {
            <em>There are no components for this project.</em>
        }
    </div>
</div>
