import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { ComponentFormComponent } from "../component-form/component-form.component";
import { CurrentComponentService } from "src/app/services/current-component/current-component.service";
import { ComponentService } from "src/app/shared/generated/api/component.service";
import { ComponentDto } from "src/app/shared/generated/model/component-dto";
import { ComponentUpsertDto } from "src/app/shared/generated/model/component-upsert-dto";
import { map } from "rxjs/operators";
import { MatIcon } from "@angular/material/icon";
import { MatButton, MatIconButton } from "@angular/material/button";
import { BackToTopComponent } from "src/app/shared/components/back-to-top/back-to-top.component";
import { AsyncPipe, NgIf } from "@angular/common";

@Component({
    selector: "component-summary-information",
    templateUrl: "./component-summary-information.component.html",
    styleUrls: ["./component-summary-information.component.scss"],
    standalone: true,
    imports: [MatButton, MatIcon, MatIconButton, ComponentFormComponent, BackToTopComponent, NgIf, AsyncPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentSummaryInformationComponent implements OnInit {
    @ViewChild("updateComponentForm", { read: ComponentFormComponent }) form: ComponentFormComponent;

    public component$: Observable<ComponentDto>;
    public component: ComponentDto;
    public currentUser: UserDto;

    public editMode = false;
    public componentUpsertDto: ComponentUpsertDto;

    userSubscription: Subscription;
    componentPutSubscription: Subscription;

    constructor(
        private componentService: ComponentService,
        private currentComponentService: CurrentComponentService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.component$ = this.currentComponentService.getCurrentComponent().pipe(
            map((data) => {
                this.component = data;
                this.componentUpsertDto = this.currentComponentService.createComponentDto(this.component);
                this.cdr.markForCheck();
                return data;
            })
        );

        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
        this.componentPutSubscription?.unsubscribe();
    }

    refreshComponent(result: any) {
        this.component = result;
        this.editMode = false;
        this.currentComponentService.setCurrentComponent(this.component);
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    save() {
        this.componentPutSubscription = this.componentService
            .componentsComponentIDPut(this.component.ComponentID, this.form.componentUpsertDto)
            .subscribe((result) => {
                this.alertService.pushAlert(new Alert("The component was successfully updated.", AlertContext.Success), 5000);
                this.refreshComponent(result);
                return result;
            });
    }

    canEdit(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Update) && !this.editMode;
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.componentUpsertDto) === JSON.stringify(this.form.componentUpsertDto);
        } else {
            return true;
        }
    }
}
