@switch (entity) { @case ("Project") {
<mat-icon [inline]="inline">view_timeline</mat-icon>
} @case ("SourceDocument") {
<mat-icon [inline]="inline">source</mat-icon>
} @case ("Commitment") {
<mat-icon [inline]="inline">handshake</mat-icon>
} @case ("ComplianceRequirement") {
<mat-icon [inline]="inline">fact_check</mat-icon>
} @case ("Component") {
<mat-icon [inline]="inline">dataset</mat-icon>
} @case ("ComplianceTracking") {
<mat-icon [inline]="inline">assignment_turned_in</mat-icon>
} @case ("CommitmentLibrary") {
<mat-icon [inline]="inline">library_books</mat-icon>
} @case ("EvidenceOfCompliance") {
<mat-icon [inline]="inline">search_check</mat-icon>
} @case ("MonitoringDashboard") {
<mat-icon [inline]="inline">monitor_heart</mat-icon>
} @case ("Scheduling") {
<mat-icon [inline]="inline">assignment_ind</mat-icon>
} }
