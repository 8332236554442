<module-header [moduleMenuItems]="moduleMenuItems">
    @if (projects$ | async; as projects) {
        <div>
            <a class="project-switcher" [matMenuTriggerFor]="projectMenu">
                <beacon-entity-icon entity="Project"></beacon-entity-icon>

                @if (currentProject$ | async; as currentProject) {
                    {{ currentProject.Name }}
                } @else {
                    Select a Project
                }
                <mat-icon [inline]="true">expand_more</mat-icon>
            </a>
            <mat-menu #projectMenu="matMenu">
                @for (project of projects; track project) {
                    @if (currentProject$ | async; as currentProject) {
                        @if (currentProject.ProjectID !== project.ProjectID) {
                            <a mat-menu-item [routerLink]="['projects', project.ProjectID]" class="selected">
                                {{ project.Name }}
                            </a>
                        }
                    } @else {
                        <a mat-menu-item [routerLink]="['projects', project.ProjectID]" class="selected">
                            {{ project.Name }}
                        </a>
                    }
                }
                <a mat-menu-item [routerLink]="['projects', 'create']">
                    <mat-icon>add</mat-icon>
                    <span>New Project</span>
                </a>
            </mat-menu>
        </div>
    }
</module-header>
<router-outlet></router-outlet>
