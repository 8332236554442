import { Component, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { MatButton } from "@angular/material/button";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { CommitmentLibraryService } from "src/app/shared/generated/api/commitment-library.service";
import { CustomRichTextComponent } from "src/app/shared/components/custom-rich-text/custom-rich-text.component";
import { CommonModule } from "@angular/common";
import { BeaconEntityIconComponent } from "src/app/shared/components/beacon-entity-icon/beacon-entity-icon.component";
import { LoadingSpinnerComponent } from "../../shared/components/loading-spinner/loading-spinner.component";
import { share } from "rxjs";

@Component({
    selector: "commitment-library-home",
    templateUrl: "./commitment-library-home.component.html",
    styleUrls: ["./commitment-library-home.component.scss"],
    standalone: true,
    imports: [MatButton, RouterLink, CustomRichTextComponent, CommonModule, BeaconEntityIconComponent, LoadingSpinnerComponent],
})
export class CommitmentLibraryHomeComponent implements OnInit {
    CustomRichTextTypeEnum = CustomRichTextTypeEnum;

    summary$ = this.commitmentLibraryService.commitmentLibrarySummaryGet().pipe(share());

    constructor(
        private router: Router,
        private commitmentLibraryService: CommitmentLibraryService
    ) {}

    ngOnInit(): void {}
}
