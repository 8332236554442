import { Component, Input } from "@angular/core";
import { ComponentSummaryDto } from "../../generated/model/component-summary-dto";
import { MatIcon } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { PillComponent } from "../pill/pill.component";
import { DatePipe } from "@angular/common";
import { PillComponentStatusComponent } from "../pill-component-status/pill-component-status.component";
import { MatTooltip } from "@angular/material/tooltip";
import { BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";

@Component({
    selector: "component-summary",
    standalone: true,
    imports: [MatIcon, RouterModule, PillComponent, DatePipe, PillComponentStatusComponent, MatTooltip, BeaconEntityIconComponent],
    templateUrl: "./component-summary.component.html",
    styleUrl: "./component-summary.component.scss",
})
export class ComponentSummaryComponent {
    @Input() component: ComponentSummaryDto;
}
