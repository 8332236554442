import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { INavItem, ModuleHeaderComponent } from "src/app/shared/components/module-header/module-header.component";

@Component({
    selector: "commitment-library-router-outlet",
    standalone: true,
    imports: [RouterOutlet, ModuleHeaderComponent],
    templateUrl: "./commitment-library-router-outlet.component.html",
    styleUrl: "./commitment-library-router-outlet.component.scss",
})
export class CommitmentLibraryRouterOutletComponent {
    moduleMenuItems: INavItem[] = [
        {
            RouterLink: ["projects"],
            Title: "Projects",
            Icon: "Project",
        },
        {
            RouterLink: ["source-documents"],
            Title: "Source Documents",
            Icon: "SourceDocument",
        },
        {
            RouterLink: ["commitments"],
            Title: "Commitments",
            Icon: "Commitment",
        },
        {
            RouterLink: ["compliance-requirements"],
            Title: "Compliance Requirements",
            Icon: "ComplianceRequirement",
        },
    ];
}
