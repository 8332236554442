import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CommitmentVersionStatusEnum } from "src/app/shared/generated/enum/commitment-version-status-enum";
import { FlagEnum } from "src/app/shared/generated/enum/flag-enum";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AuthenticationService } from "../authentication.service";
import { CommitmentUpsertDto } from "src/app/shared/generated/model/commitment-upsert-dto";

@Injectable({
    providedIn: "root",
})
export class CurrentCommitmentService {
    currentCommitment$ = new BehaviorSubject<CommitmentDto>(null);

    constructor(private authenticationService: AuthenticationService) {}

    setCurrentCommitment(commitment: CommitmentDto): void {
        this.currentCommitment$.next(commitment);
    }

    getCurrentCommitment(): Observable<CommitmentDto> {
        return this.currentCommitment$.asObservable();
    }

    createCommitmentDto(commitment: CommitmentDto) {
        const commimentDto = new CommitmentUpsertDto({
            ClientCommitmentID: commitment?.ClientCommitmentID,
            Title: commitment?.Title,
            CommitmentTypeID: commitment?.CommitmentType?.CommitmentTypeID,
            ResourceCategoryID: commitment?.ResourceCategory?.ResourceCategoryID,
            SourceID: commitment?.Source?.SourceID ?? (commitment as any)?.SourceID,
            TechnicalLeadIDs: commitment?.TechnicalLeadUsers?.map((complianceLead) => complianceLead.UserID),
            FullCommitmentText: commitment?.FullCommitmentText,
            Seasonality: commitment?.Seasonality,
            WorkActivities: commitment?.WorkActivities,
            LocationDescription: commitment?.LocationDescription,
            GeneralGuidance: commitment?.GeneralGuidance,
            OriginatorOfChange: commitment?.OriginatorOfChange,
            SummaryOfChange: commitment?.SummaryOfChange,
            ApprovedDate: commitment?.ApprovedDate,
            ApprovedUserID: commitment?.ApprovedUser?.UserID,
        });
        return commimentDto;
    }

    canEditCurrentCommitment(currentUser: UserDto, commitment: CommitmentDto): boolean {
        if (commitment?.CommitmentVersionStatus.CommitmentVersionStatusID != CommitmentVersionStatusEnum.Draft) {
            return this.authenticationService.hasFlag(currentUser, FlagEnum.CanEditFinalizedCommitments);
        }

        return (
            commitment?.CommitmentVersionStatus.CommitmentVersionStatusID == CommitmentVersionStatusEnum.Draft &&
            this.authenticationService.hasPermission(currentUser, PermissionEnum.CommitmentRights, RightsEnum.Update)
        );
    }

    canDeleteCurrentCommitment(currentUser: UserDto, commitment: CommitmentDto): boolean {
        if (commitment?.CommitmentVersionStatus.CommitmentVersionStatusID != CommitmentVersionStatusEnum.Draft) {
            return this.authenticationService.hasFlag(currentUser, FlagEnum.CanEditFinalizedCommitments);
        }

        return (
            commitment?.CommitmentVersionStatus.CommitmentVersionStatusID == CommitmentVersionStatusEnum.Draft &&
            this.authenticationService.hasPermission(currentUser, PermissionEnum.CommitmentRights, RightsEnum.Delete)
        );
    }
}
