/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChecklistDto } from '../model/checklist-dto';
import { ChecklistItemDto } from '../model/checklist-item-dto';
import { ChecklistItemStatusDto } from '../model/checklist-item-status-dto';
import { ChecklistUpsertDto } from '../model/checklist-upsert-dto';
import { ComponentChecklistsUpsertDto } from '../model/component-checklists-upsert-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiService } from '../../services';
import { TenantService } from '../../services/tenant/tenant-service.service';


@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

    protected basePath = 'http://localhost';
    protected unmodifiedBasePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService, private tenantService: TenantService) {
        if (basePath) {
            this.basePath = basePath;
            this.unmodifiedBasePath = basePath;
        }

        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
            this.unmodifiedBasePath = basePath || configuration.basePath || this.basePath;
        }

        tenantService.currentTenant$.subscribe(tenant => {
            this.setBasePath(tenant);
        });
    }

    private setBasePath(tenant) {
        if (tenant && tenant.SubDomain) {
            let schemalessBasePath = this.unmodifiedBasePath.replace("https://", "").replace("http://", "");
            this.basePath = `https://${tenant.SubDomain}.${schemalessBasePath}`;
        } else {                
            this.basePath = this.unmodifiedBasePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checklistItemStatusesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ChecklistItemStatusDto>>;
    public checklistItemStatusesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChecklistItemStatusDto>>>;
    public checklistItemStatusesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChecklistItemStatusDto>>>;
    public checklistItemStatusesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ChecklistService")){
            let serviceRequest = this.httpClient.get<Array<ChecklistItemStatusDto>>(`${this.basePath}/checklistItemStatuses`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<ChecklistItemStatusDto>>(`${this.basePath}/checklistItemStatuses`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param checklistID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistChecklistIDGet(componentID: string, checklistID: string, observe?: 'body', reportProgress?: boolean): Observable<ChecklistDto>;
    public componentsComponentIDChecklistChecklistIDGet(componentID: string, checklistID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChecklistDto>>;
    public componentsComponentIDChecklistChecklistIDGet(componentID: string, checklistID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChecklistDto>>;
    public componentsComponentIDChecklistChecklistIDGet(componentID: string, checklistID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistChecklistIDGet.');
        }

        if (checklistID === null || checklistID === undefined) {
            throw new Error('Required parameter checklistID was null or undefined when calling componentsComponentIDChecklistChecklistIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ChecklistService")){
            let serviceRequest = this.httpClient.get<ChecklistDto>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklist/${encodeURIComponent(String(checklistID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<ChecklistDto>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklist/${encodeURIComponent(String(checklistID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param checklistItemID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistItemsChecklistItemIDGet(componentID: string, checklistItemID: string, observe?: 'body', reportProgress?: boolean): Observable<ChecklistItemDto>;
    public componentsComponentIDChecklistItemsChecklistItemIDGet(componentID: string, checklistItemID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChecklistItemDto>>;
    public componentsComponentIDChecklistItemsChecklistItemIDGet(componentID: string, checklistItemID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChecklistItemDto>>;
    public componentsComponentIDChecklistItemsChecklistItemIDGet(componentID: string, checklistItemID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistItemsChecklistItemIDGet.');
        }

        if (checklistItemID === null || checklistItemID === undefined) {
            throw new Error('Required parameter checklistItemID was null or undefined when calling componentsComponentIDChecklistItemsChecklistItemIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ChecklistService")){
            let serviceRequest = this.httpClient.get<ChecklistItemDto>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklistItems/${encodeURIComponent(String(checklistItemID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<ChecklistItemDto>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklistItems/${encodeURIComponent(String(checklistItemID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param checklistItemID 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistItemsChecklistItemIDPut(componentID: string, checklistItemID: string, body?: number, observe?: 'body', reportProgress?: boolean): Observable<ChecklistItemDto>;
    public componentsComponentIDChecklistItemsChecklistItemIDPut(componentID: string, checklistItemID: string, body?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChecklistItemDto>>;
    public componentsComponentIDChecklistItemsChecklistItemIDPut(componentID: string, checklistItemID: string, body?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChecklistItemDto>>;
    public componentsComponentIDChecklistItemsChecklistItemIDPut(componentID: string, checklistItemID: string, body?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistItemsChecklistItemIDPut.');
        }

        if (checklistItemID === null || checklistItemID === undefined) {
            throw new Error('Required parameter checklistItemID was null or undefined when calling componentsComponentIDChecklistItemsChecklistItemIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if(this.tenantService.excludedServiceNames.includes("ChecklistService")){
            let serviceRequest = this.httpClient.put<ChecklistItemDto>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklistItems/${encodeURIComponent(String(checklistItemID))}`,
                body,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.put<ChecklistItemDto>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklistItems/${encodeURIComponent(String(checklistItemID))}`,
                    body,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistItemsGet(componentID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChecklistItemDto>>;
    public componentsComponentIDChecklistItemsGet(componentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChecklistItemDto>>>;
    public componentsComponentIDChecklistItemsGet(componentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChecklistItemDto>>>;
    public componentsComponentIDChecklistItemsGet(componentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistItemsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ChecklistService")){
            let serviceRequest = this.httpClient.get<Array<ChecklistItemDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklistItems`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<ChecklistItemDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklistItems`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param componentChecklistsUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistItemsPut(componentID: string, componentChecklistsUpsertDto?: ComponentChecklistsUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<Array<ChecklistItemDto>>;
    public componentsComponentIDChecklistItemsPut(componentID: string, componentChecklistsUpsertDto?: ComponentChecklistsUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChecklistItemDto>>>;
    public componentsComponentIDChecklistItemsPut(componentID: string, componentChecklistsUpsertDto?: ComponentChecklistsUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChecklistItemDto>>>;
    public componentsComponentIDChecklistItemsPut(componentID: string, componentChecklistsUpsertDto?: ComponentChecklistsUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistItemsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if(this.tenantService.excludedServiceNames.includes("ChecklistService")){
            let serviceRequest = this.httpClient.put<Array<ChecklistItemDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklistItems`,
                componentChecklistsUpsertDto,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.put<Array<ChecklistItemDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklistItems`,
                    componentChecklistsUpsertDto,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param checklistID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistsChecklistIDDelete(componentID: string, checklistID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public componentsComponentIDChecklistsChecklistIDDelete(componentID: string, checklistID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public componentsComponentIDChecklistsChecklistIDDelete(componentID: string, checklistID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public componentsComponentIDChecklistsChecklistIDDelete(componentID: string, checklistID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistsChecklistIDDelete.');
        }

        if (checklistID === null || checklistID === undefined) {
            throw new Error('Required parameter checklistID was null or undefined when calling componentsComponentIDChecklistsChecklistIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ChecklistService")){
            let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklists/${encodeURIComponent(String(checklistID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklists/${encodeURIComponent(String(checklistID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param checklistID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistsChecklistIDStatusReportDownloadGet(componentID: string, checklistID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public componentsComponentIDChecklistsChecklistIDStatusReportDownloadGet(componentID: string, checklistID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public componentsComponentIDChecklistsChecklistIDStatusReportDownloadGet(componentID: string, checklistID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public componentsComponentIDChecklistsChecklistIDStatusReportDownloadGet(componentID: string, checklistID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistsChecklistIDStatusReportDownloadGet.');
        }

        if (checklistID === null || checklistID === undefined) {
            throw new Error('Required parameter checklistID was null or undefined when calling componentsComponentIDChecklistsChecklistIDStatusReportDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ChecklistService")){
            let serviceRequest = this.httpClient.get<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklists/${encodeURIComponent(String(checklistID))}/status-report/download`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklists/${encodeURIComponent(String(checklistID))}/status-report/download`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistsGet(componentID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChecklistDto>>;
    public componentsComponentIDChecklistsGet(componentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChecklistDto>>>;
    public componentsComponentIDChecklistsGet(componentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChecklistDto>>>;
    public componentsComponentIDChecklistsGet(componentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ChecklistService")){
            let serviceRequest = this.httpClient.get<Array<ChecklistDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklists`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<ChecklistDto>>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklists`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param checklistUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistsPut(componentID: string, checklistUpsertDto?: ChecklistUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ChecklistDto>;
    public componentsComponentIDChecklistsPut(componentID: string, checklistUpsertDto?: ChecklistUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChecklistDto>>;
    public componentsComponentIDChecklistsPut(componentID: string, checklistUpsertDto?: ChecklistUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChecklistDto>>;
    public componentsComponentIDChecklistsPut(componentID: string, checklistUpsertDto?: ChecklistUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if(this.tenantService.excludedServiceNames.includes("ChecklistService")){
            let serviceRequest = this.httpClient.put<ChecklistDto>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklists`,
                checklistUpsertDto,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.put<ChecklistDto>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklists`,
                    checklistUpsertDto,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param componentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public componentsComponentIDChecklistsStatusReportsDownloadGet(componentID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public componentsComponentIDChecklistsStatusReportsDownloadGet(componentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public componentsComponentIDChecklistsStatusReportsDownloadGet(componentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public componentsComponentIDChecklistsStatusReportsDownloadGet(componentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (componentID === null || componentID === undefined) {
            throw new Error('Required parameter componentID was null or undefined when calling componentsComponentIDChecklistsStatusReportsDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("ChecklistService")){
            let serviceRequest = this.httpClient.get<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklists/status-reports/download`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<any>(`${this.basePath}/components/${encodeURIComponent(String(componentID))}/checklists/status-reports/download`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

}
