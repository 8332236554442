<form class="form grid-12" #projectForm="ngForm">
    <div class="g-col-6">
        <esa-material-ff name="Name" [editMode]="editMode" [required]="true" type="text" [(ngModel)]="projectUpsertDto.Name" [customLabel]="nameCustomLabel">
        </esa-material-ff>
        <ng-template #nameCustomLabel>
            <custom-form-label label="Name" fieldDefinitionType="Name"></custom-form-label>
        </ng-template>

        <esa-material-ff name="Start Date" [editMode]="editMode" [required]="true" type="date" [(ngModel)]="projectUpsertDto.StartDate" [label]="'Start Date'">
        </esa-material-ff>

        <esa-material-ff name="End Date" [editMode]="editMode" [required]="true" type="date" [(ngModel)]="projectUpsertDto.EndDate" [label]="'End Date'">
        </esa-material-ff>
    </div>

    <div class="g-col-12">
        <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
            <custom-form-label label="Description" fieldDefinitionType="Description"></custom-form-label>
            <div class="value" *ngIf="editMode">
                <div class="grid-12">
                    <div class="g-col-12">
                        <tinymce-editor #tinyMceEditor name="Description" [(content)]="projectUpsertDto.Description"></tinymce-editor>
                    </div>
                </div>
            </div>
            <div
                [innerHTML]="projectUpsertDto.Description === null ? '-' : (projectUpsertDto.Description | bypassHtml)"
                class="value-display"
                *ngIf="!editMode"></div>
        </div>
    </div>

    @if (editMode) {
        <div class="g-col-12 mt-4">
            <div class="kv-pair">
                <esa-label label="Files"></esa-label>
                <div class="value file-upload">
                    <file-drop-upload [multiple]="true" (filesChanged)="uploadFilesChanged($event)" />
                    @if (files.length > 0) {
                        <div class="file-upload__files mt-3">
                            @for (file of files; track file) {
                                <simple-file-display
                                    [isNotYetUploaded]="true"
                                    [fileName]="file.name"
                                    [displayDelete]="true"
                                    (deleteFile)="deleteNewFile(file)"></simple-file-display>
                            }
                        </div>
                    }
                </div>
            </div>
            @if (isLoading) {
                <beacon-loading-overlay>
                    Saving Evidence of Compliance...
                    <loading-spinner color="accent" [diameter]="50" />
                </beacon-loading-overlay>
            }
        </div>
    } @else {
        <div class="g-col-12">
            <project-file-list></project-file-list>
        </div>
    }

    <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
        <span [matTooltip]="!projectForm.form.valid ? 'You are missing requried fields.' : ''">
            <button mat-flat-button style="margin-right: 1rem" color="primary" (click)="saveForm(form)" [disabled]="!projectForm.form.valid">
                <mat-icon>save</mat-icon> Save
            </button>
        </span>
        <button mat-button color="primary" (click)="cancelEditMode()"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
</form>
