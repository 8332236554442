import { ComponentRef, Directive, ElementRef, HostBinding, Input, Renderer2, ViewContainerRef } from "@angular/core";
import { LoadingSpinnerComponent } from "../../components/loading-spinner/loading-spinner.component";

@Directive({
    selector: "button[buttonLoading]",
    standalone: true,
})
export class ButtonLoadingDirective {
    private _loading: boolean;
    // private spinnerRef: ComponentRef<LoadingSpinnerComponent>;
    // private wrapperRef: HTMLElement;

    @Input() set buttonLoading(loading: boolean) {
        this._loading = loading;
        // this.updateLoadingState();
    }

    @HostBinding("disabled") get disabled() {
        return this._loading;
    }

    constructor(private el: ElementRef, private viewContainerRef: ViewContainerRef, private renderer: Renderer2) {}

    // private updateLoadingState() {
    //     if (this._loading) {
    //         if (!this.spinnerRef) {
    //             this.viewContainerRef.clear();

    //             // Create wrapper element if it doesn't exist
    //             if (!this.wrapperRef) {
    //                 this.wrapperRef = this.renderer.createElement("div");
    //                 this.renderer.setStyle(this.wrapperRef, "position", "relative");
    //                 this.renderer.setStyle(this.wrapperRef, "display", "inline-block");

    //                 // Move the original element into the wrapper
    //                 const parent = this.el.nativeElement.parentNode;
    //                 this.renderer.appendChild(this.wrapperRef, this.el.nativeElement);
    //                 // this.renderer.appendChild(parent, this.wrapperRef);

    //                 this.renderer.appendChild(parent, this.wrapperRef);
    //             }

    //             // Create and append the loading spinner
    //             this.spinnerRef = this.viewContainerRef.createComponent<LoadingSpinnerComponent>(LoadingSpinnerComponent);
    //             this.spinnerRef.instance.diameter = 48;
    //             this.spinnerRef.instance.strokeWidth = 3;
    //             debugger;
    //             this.renderer.appendChild(this.wrapperRef, this.spinnerRef.location.nativeElement);
    //         }
    //     } else {
    //         if (this.spinnerRef) {
    //             this.spinnerRef.destroy();
    //             this.spinnerRef = null;
    //         }

    //         // Remove the wrapper and move the original element back to its place
    //         if (this.wrapperRef) {
    //             const parent = this.wrapperRef.parentNode;
    //             this.renderer.appendChild(parent, this.el.nativeElement);
    //             this.renderer.removeChild(parent, this.wrapperRef);
    //             this.wrapperRef = null;
    //         }
    //     }
    // }
}
