<ul>
    @for (moduleMenuItem of moduleMenuItems; track moduleMenuItem) {
        <li>
            <a [routerLink]="moduleMenuItem.RouterLink" routerLinkActive="active">
                @if (moduleMenuItem.Icon) {
                    <beacon-entity-icon [entity]="moduleMenuItem.Icon" />
                }
                {{ moduleMenuItem.Title }}
            </a>
        </li>
    }
</ul>

<ng-content></ng-content>
